/* body {
  overflow-y: hidden !important;
} */

externalIframe {
  position: absolute;
  left: 0px;
  right: 0px;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  border: none;
}
