.archiveFileDownload {
  padding: 8px;
  padding-left: 14px;
  display: block;
  color: black;
}

.archiveFileDownload:hover {
  color: black;
}

.archiveFileDownload0 {
  background-color: #ebebeb;
}

.archiveFileDownload0:hover {
  background-color: #dddddd;
}

.archiveFileDownload1 {
  background-color: rgb(228 228 228);
}

.archiveFileDownload1:hover {
  background-color: #cccccc;
}

.fileIcon {
  position: relative;
  top: 3px;
  left: 3px;
}

.fileTitle {
  position: relative;
  bottom: 2px;
  left: 10px;
}

.fileSize {
  float: right;
}
