@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
body {
  font-family: 'Montserrat', sans-serif !important;
  background-color: black;
  background-image: url(/static/media/background.ef4890cf.png) !important;
  color: black;
  background-repeat: repeat !important;
  margin: 0px !important;
  height: 100%;
  overflow-y: scroll;
}

#headerPhoto {
  margin-bottom: 0px;
  background-size: cover;
  width: 100%;
}

.loading {
  margin-right: '15px';
  position: 'relative';
  top: '4px';
}

/* center loading... */
#Loading {
	color: rgb(51, 51, 51);
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	text-align: center;
}

.archiveFileDownload {
  padding: 8px;
  padding-left: 14px;
  display: block;
  color: black;
}

.archiveFileDownload:hover {
  color: black;
}

.archiveFileDownload0 {
  background-color: #ebebeb;
}

.archiveFileDownload0:hover {
  background-color: #dddddd;
}

.archiveFileDownload1 {
  background-color: rgb(228 228 228);
}

.archiveFileDownload1:hover {
  background-color: #cccccc;
}

.fileIcon {
  position: relative;
  top: 3px;
  left: 3px;
}

.fileTitle {
  position: relative;
  bottom: 2px;
  left: 10px;
}

.fileSize {
  float: right;
}

/* body {
  overflow-y: hidden !important;
} */

externalIframe {
  position: absolute;
  left: 0px;
  right: 0px;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  border: none;
}

.announcement {
	margin-top: 50px;
	margin-bottom: 50px;
}

#serverAddress {
  font-size: calc(1% + 0.7vw + 0.7vh);
}

.serverStatus {
  font-family: 'Roboto', sans-serif;
}

.serverOnline {
  color: #0fe300;
}

.serverOffline {
  color: #ff0000;
}

.playerHead {
  width: 50px;
  margin-left: 15px;
  margin-top: 15px;
}

#remindImage {
  width: 100%;
}

.textWrap {
  overflow-wrap: break-word;
}

#bodyContainer {
  margin-top: 30px;
  width: 100%;
  max-width: 100%;
}

#centerColumn {
}

#rightColumn {
}

.pluginIcon {
  object-fit: contain;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  cursor: pointer;
}

.pluginName {
  display: inline-block;
  position: relative;
  left: 10px;
  top: 3px;
}

.syntax {
  background: #dfdfdf !important;
  padding: 15px;
  font-size: 15px;
  overflow-x: auto;
  border-radius: 8px;
  font-family: monospace, monospace;
}

.commandListItem {
  background-color: #f6f6f6;
}

