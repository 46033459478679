.pluginIcon {
  object-fit: contain;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  cursor: pointer;
}

.pluginName {
  display: inline-block;
  position: relative;
  left: 10px;
  top: 3px;
}

.syntax {
  background: #dfdfdf !important;
  padding: 15px;
  font-size: 15px;
  overflow-x: auto;
  border-radius: 8px;
  font-family: monospace, monospace;
}

.commandListItem {
  background-color: #f6f6f6;
}
