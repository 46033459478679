#serverAddress {
  font-size: calc(1% + 0.7vw + 0.7vh);
}

.serverStatus {
  font-family: 'Roboto', sans-serif;
}

.serverOnline {
  color: #0fe300;
}

.serverOffline {
  color: #ff0000;
}

.playerHead {
  width: 50px;
  margin-left: 15px;
  margin-top: 15px;
}
