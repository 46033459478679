@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

body {
  font-family: 'Montserrat', sans-serif !important;
  background-color: black;
  background-image: url('../assets/background.png') !important;
  color: black;
  background-repeat: repeat !important;
  margin: 0px !important;
  height: 100%;
  overflow-y: scroll;
}

#headerPhoto {
  margin-bottom: 0px;
  background-size: cover;
  width: 100%;
}

.loading {
  margin-right: '15px';
  position: 'relative';
  top: '4px';
}
