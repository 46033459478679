/* center loading... */
#Loading {
	color: rgb(51, 51, 51);
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
